// TODO: once new Global nav implementation complete we have to remove these header related css
@import '../../../libs/shared/mi-ui-global-styles/src/styles/base/variables/colors';

.m-header__nav {
  .m-header__sub-nav {
    padding: 0;
    .cl__list {
      padding: 0;
    }
    @media screen and (min-width: 992px) {
      padding-bottom: 3.25rem;
      padding-top: 2.875rem;

      .cardlinks.aem-GridColumn.aem-GridColumn--default--3 {
        padding: 0;
      }
    }

    @media screen and (max-width: 992px) {
      .aem-Grid.aem-Grid--default--12 > .aem-GridColumn {
        &.cardlinks,
        &.cardarticle {
          float: left;
        }
      }
    }
  }
}

// multiline nav items are cropping bottom side hence removing top margin for specific resolution
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .m-header__nav-items {
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .m-header {
    right: 0;
    left: 0;
  }

  // need to revisit after global navigation live. (WEB-72316)
  .m-header__container .m-header__content {
    padding: 0;
  }
}

// need to revisit after global navigation live. (WEB-72316)
@media screen and (min-width: 1025px) {
  .m-header__container .m-header__content {
    .m-header__logo-nav-items {
      margin-left: -0.5rem;
    }
  }
}

.target-background {
  & > div:first-child {
    background-color: $base-10;
    & > div:first-child {
      visibility: hidden;
    }
  }
}

.herobanner-ratio-16x9 > div:first-child {
  aspect-ratio: 2 / 3;

  @media screen and (min-width: 768px) {
    aspect-ratio: 16 / 9;
  }
}

.herobanner-ratio-2x1 > div:first-child {
  aspect-ratio: 2 / 3;

  @media screen and (min-width: 768px) {
    aspect-ratio: 2 / 1;
  }
}

.herobanner-ratio-3x1 > div:first-child {
  aspect-ratio: 1 / 1;

  @media screen and (min-width: 768px) {
    aspect-ratio: 3 / 1;
  }
}

.basicpage {
  background-color: $neutral-10;
}

// WEB-71780 - Hide footer session data
// WEB-90287 - Show footer session detail, commenting out in case we want to re-hide details
// .dark-theme .mt-copyright-component .js-session-detail,
// .mt-copyright-component .js-session-detail {
//   color: transparent;
// }

// WEB-75255 - Footer Language Selector is getting hidden with search component - Tablet and Mobile - need to revisit once removed hybrid footer
.mdc-dialog--open {
  z-index: 9999;
}

// WEB-77063 - need to revisit after shop team fix (DE calendar weekdays alignment)

@media screen and (min-width: 992px) {
  .date-picker-wrapper .shop-datepicker-modal .DayPicker-WeekdaysRow {
    gap: unset !important;
    width: 100%;
    justify-content: space-between;
  }

  .DayPicker .DayPicker-wrapper .DayPicker-Months .DayPicker-Month .DayPicker-Weekdays .DayPicker-Weekday {
    text-align: center;
    width: 2.5rem;
  }

  .date-picker-wrapper .shop-datepicker-modal .DayPicker-Week {
    gap: unset;
    text-align: center;
  }

  .date-picker-wrapper .shop-datepicker-modal {
    &:dir(rtl) {
      right: 8px !important;
      left: 0px !important;
    }
  }
}

.m-stepper-wrapper {
  &:dir(rtl) {
    padding: 0.5rem !important;
  }
}

.skip-links {
  background: $base-20;
  height: 50px;
  left: 0px;
  padding: 16px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  &.display-on-focus:focus {
    transform: translateY(0%);
    z-index: 3000;
  }
}
